<template>
  <div :class="{ 'text-theme-6': isAtThreshold }">
    {{ displayedTime }}
  </div>
</template>

<script>
import { ref, onMounted, watch, inject, computed } from "vue";

export default {
  props: {
    duration: {
      type: Number,
      default: 0,
      validator: value => value >= 0
    },
    durationType: {
      type: String,
      default: "minutes",
      validator: value => ["minutes", "hours"].includes(value)
    },
    /**
     * In milliseconds
     */
    threshold: {
      type: Number,
      default: 0,
      validator: value => value >= 0
    }
  },
  emits: ["expired", "update:time"],
  setup(props, context) {
    // Inject
    const moment = inject("moment");

    // Data
    const timer = ref(null);
    const time = ref(0);
    const startTime = ref("");
    const durationTime = ref("");

    // Computed
    const displayedTime = computed(() => {
      return moment.utc(time.value).format("HH : mm : ss");
    });

    const isAtThreshold = computed(() => {
      return time.value <= props.threshold;
    });

    // Methods
    const start = () => {
      startTime.value = moment(new Date());
      durationTime.value = moment
        .duration(props.duration, props.durationType)
        .asMilliseconds();

      timer.value = setInterval(() => {
        const now = moment(new Date());
        time.value = durationTime.value - now.diff(startTime.value);

        if (time.value > 0) {
          context.emit("update:time", displayedTime.value);
        } else {
          reset();
        }
      }, 1000);
    };

    const reset = () => {
      stop();
      time.value = 0;
    };

    const stop = () => {
      clearInterval(timer.value);
      timer.value = 0;
    };

    // Lifecycle Hooks
    onMounted(() => {
      start();
    });

    // Watch
    watch(timer, () => {
      if (timer.value === 0) {
        context.emit("expired");
      }
    });

    return {
      isAtThreshold,
      displayedTime
    };
  }
};
</script>
