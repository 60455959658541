<template>
  <div>
    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <Questionnaire
      v-else
      :id="id"
      :data="data"
      :questions="data.questionnaire"
    />
  </div>
</template>

<script>
import { computed, onMounted, provide, ref } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useRequest from "@/composables/useRequest";
// Components
import Questionnaire from "./Questionnaire";

export default {
  components: {
    Questionnaire
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { request } = useRequest();
    const { getText } = useTexts();

    // Provide
    provide("evaluationType", "observations");

    // Data
    const data = ref({});
    const isLoading = ref(false);

    // Methods
    const getData = async () => {
      const response = await request({
        endpoint: "learning.evaluations.resume",
        pathParams: {
          id: props.id
        }
      });

      data.value = response?.payload?.data ?? {};
    };

    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.preview")} - ${t("app.quizzes", 2)} - ${t("app.evaluations", 2)} - ${t("app.teaching")}`;

      const name = getText(data.value?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Lifecycle Hooks
    onMounted(async () => {
      isLoading.value = true;
      await getData();
      isLoading.value = false;
    });

    return {
      data,
      isLoading,
      documentTitle
    };
  }
};
</script>
