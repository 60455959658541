<template>
  <div>
    <div class="grid grid-cols-3 gap-4">
      <div class="col-span-3 lg:col-span-1">
        <div class="sticky pt-10 top-0">
          <VCard>
            <template #title>
              {{ title }}
            </template>

            <template #subtitles>
              {{ subtitles }}
            </template>

            <template v-if="!isObservation" #title-actions>
              <VTimer
                :duration="duration"
                :duration-type="duration_type"
                :threshold="60000"
                class="text-lg"
                @update:time="updateTime"
                @expired="setExpired"
              />
            </template>

            <template #content>
              <div
                v-for="(question, index) in questions"
                :key="`overview-${question?.question?.id}`"
              >
                <a
                  class="flex justify-between items-center cursor-pointer"
                  :class="{ 'mt-4': index !== 0 }"
                  @click="onClickScroll(`question-${index}`)"
                >
                  <div>{{ overviewTitle }} {{ index + 1 }}</div>

                  <div>
                    {{ question?.question.grade }}

                    <span class="lowercase">
                      {{ $t("app.points") }}
                    </span>

                    ({{ getPercentage(question?.question?.grade) }}%)
                  </div>
                </a>
              </div>
            </template>

            <template v-if="!isView" #more-content>
              <VFile
                v-if="isObservation"
                v-model="file_ids"
                multiple
                class="mb-4"
              >
                <template #label>
                  <VLine class="form-label" :label="$t('app.file_ids')" />
                </template>
              </VFile>

              <VComment v-model="comments" :label="$t('app.comments')" />
            </template>

            <template #actions>
              <div
                class="flex items-center"
                :class="isObservation ? 'justify-between' : 'justify-end'"
              >
                <VButton
                  v-if="isObservation"
                  :is-loading="isPausing"
                  class="btn-outline-primary"
                  :label="$t('app.pause')"
                  :disabled="isView"
                  @click="pauseObservation"
                />

                <SubmitEvaluationButton
                  :id="id"
                  :is-observation="isObservation"
                  :user-id="observerId"
                  :comments="comments"
                  :file-ids="file_ids"
                  @submitted="onClickSubmit"
                />
              </div>
            </template>
          </VCard>
        </div>
      </div>

      <div class="col-span-3 pt-10 lg:col-span-2">
        <Questions
          :questions="questions"
          :is-observation="isObservation"
          :readonly="isView || isTimerExpired"
          :display-correct-answers="displayCorrectAnswers"
          :registration-evaluation-id="id"
        />
      </div>
    </div>

    <WarningModal
      v-if="isModalVisible"
      id="leave-page"
      :warning="$t('app.quiz_leave_msg')"
    >
      <template #actions>
        <div class="px-5 pb-8 text-center">
          <VButton
            class="btn-outline-secondary mr-2"
            :label="$t('app.cancel')"
            @click="onClickCancelLeavePage"
          />

          <VButton
            class="btn-primary"
            :label="$t('app.confirm')"
            :is-loading="isLeaving"
            @click="onClickConfirmLeavePage"
          />
        </div>
      </template>
    </WarningModal>
  </div>
</template>

<script>
import { onMounted, computed, inject, ref, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { onBeforeRouteLeave, useRouter } from "vue-router";
// Composables
import useTexts from "@/composables/useTexts";
import useOptions from "@/composables/useOptions";
import useModal from "@/composables/useModal";
import useRequest from "@/composables/useRequest";
// Components
import Questions from "./Questions";
import SubmitEvaluationButton from "./SubmitEvaluationButton";
import VTimer from "@/components/VTimer";
import VCard from "@/components/VCard";
import VButton from "@/components/VButton";
import VFile from "@/components/inputs/VFile";
import VLine from "@/components/VLine";
import VComment from "@/components/inputs/VComment";
import WarningModal from "@/components/modals/WarningModal";

export default {
  components: {
    SubmitEvaluationButton,
    Questions,
    VTimer,
    VCard,
    VButton,
    VFile,
    VLine,
    WarningModal,
    VComment
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    questions: {
      type: Array,
      default: () => []
    },
    isView: {
      type: Boolean,
      default: false
    },
    displayCorrectAnswers: {
      type: Boolean,
      default: false
    },
    isConfirmLeavePageIgnored: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const router = useRouter();

    // Inject
    const isConfirmLeavePageRequired = inject(
      "isConfirmLeavePageRequired",
      false
    );

    // Data
    const isTimerExpired = ref(false);
    const time = ref("");
    const comments = ref("");
    const file_ids = ref([]);
    const isLeavePageConfirmed = ref(false);
    const isLeaving = ref(false);

    // Composables
    const { isModalVisible, hideModal, showModal } = useModal("#leave-page");
    const { getText } = useTexts();
    const { ALL_OPTIONS } = useOptions();
    const { request: pause, isRequesting: isPausing, isSuccess } = useRequest();

    // Computed
    const registration = computed(() => props.data?.registration_evaluation);
    const evaluation = computed(() => registration.value?.evaluation);
    const observerId = computed(() => registration.value?.observer_id);
    const title = computed(() => getText(evaluation.value?.texts, "name"));
    // eslint-disable-next-line
    const subtitles = computed(() => getText(evaluation.value?.texts, "details"));
    const duration = computed(() => evaluation.value?.duration);
    const duration_type = computed(() => evaluation.value?.duration_type);
    // eslint-disable-next-line
    const isObservation = computed(() => evaluation.value?.type === ALL_OPTIONS.OBSERVATION.value);
    // eslint-disable-next-line
    const overviewTitle = computed(() => isObservation.value ? t("app.requirements") : t("app.questions"));
    const totalGrade = computed(() => {
      const total = props.questions.reduce((acc, question) => {
        const grade = question?.question?.grade ?? 0;

        return acc + grade;
      }, 0);

      return total;
    });

    // Methods
    const setExpired = () => {
      isTimerExpired.value = true;
    };

    const updateTime = value => {
      time.value = value.split(" ").join("");
    };

    const onBeforeUnload = e => {
      e.preventDefault();
      e.returnValue = t("app.quiz_leave_msg");
    };

    const pauseObservation = async () => {
      const response = await pause({
        endpoint: "learning.evaluations.pause",
        pathParams: {
          id: props.id
        }
      });

      if (isSuccess(response)) {
        router.go(-1);
      }
    };

    const onClickScroll = id => {
      const el = document.getElementById(id);
      el.scrollIntoView({ behavior: "smooth" });
    };

    const getPercentage = grade => {
      if (!totalGrade.value) {
        return 0;
      }

      const percentage = (grade / totalGrade.value) * 100;

      return percentage.toFixed(0);
    };

    const onClickCancelLeavePage = () => {
      hideModal();
      isLeavePageConfirmed.value = false;
    };

    const onClickConfirmLeavePage = () => {
      isLeavePageConfirmed.value = true;
      isLeaving.value = true;
      hideModal();

      /**
       * @WARNING
       * this is wrapped in a setTimeout because sometimes it redirect before it has
       * time to hide the modal, and since the modal is hidden based on its id in the DOM
       * and with the redirection, the id is no longer in the DOM, the modal can never get hidden
       */
      setTimeout(() => {
        router.go(-1);
      }, 500);

      isLeaving.value = false;
    };

    const onClickSubmit = () => {
      isLeavePageConfirmed.value = true;
      router.go(-1);
    };

    // Lifecycle Hooks
    onMounted(async () => {
      if (!isObservation.value && !props.isView) {
        window.addEventListener("beforeunload", onBeforeUnload);
      }
    });

    onBeforeUnmount(() => {
      if (!isObservation.value && !props.isView) {
        window.removeEventListener("beforeunload", onBeforeUnload);
      }
    });

    // Navigation Guards
    onBeforeRouteLeave(() => {
      if (
        !isConfirmLeavePageRequired ||
        props.isConfirmLeavePageIgnored ||
        isLeavePageConfirmed.value ||
        isObservation.value ||
        props.isView
      ) {
        return true;
      }

      showModal();
      return false;
    });

    return {
      isLeaving,
      onClickSubmit,
      onClickCancelLeavePage,
      onClickConfirmLeavePage,
      observerId,
      overviewTitle,
      subtitles,
      onClickScroll,
      comments,
      file_ids,
      pauseObservation,
      isObservation,
      title,
      duration,
      duration_type,
      isTimerExpired,
      setExpired,
      updateTime,
      getPercentage,
      // useModal
      isModalVisible,
      // useRequest
      isPausing
    };
  }
};
</script>
